import React from "react";
import './Loading.css';
import WebCalc from '../Images/Website_Loading.svg';

function Loading() {

    return (
        <div className="load_back">
            <div className="load_container">
                <div className="title">
                    Calculating...
                    <br/>
                    <img src={WebCalc} alt="Web Calc" className="web_calc_img" height={400} width={400} />
                </div>
                <div className="body_con">
                    <p>Did you know...</p>
                    <br />
                    The annual carbon footprint of the websites of the 100 biggest US advertisers was similar to that of the housing electricity consumption of the city of Los Angeles? By reducing the environmental impact of your website, not only are you reducing your carbon emissions, but you are also contributing to the larger goal of combating climate change.
                </div>
            </div>
        </div>
    );
}



export default Loading;