import React from "react";
import './Footer.css';
import FootHead from '../Images/footsprint_header.png';
import Lab from '../Images/Labelium_white.png';

function Footer() {

    return (
        <div className="footer">
            <div className="footer_main">
                <div className="foot_head_img">
                    <img src={FootHead} className="foot_img" alt="Footer Img" height={75} width={295} />
                </div>
                <div className="foot_head">

                    <p className="foot_def"><b>Footsprint is a part of the Labelium group.</b></p>
                    <br/>
                    <p className="foot_info">Labelium is an agency specialized in international digital performance. We are based in Paris, London, Barcelona, Mexico, Toronto, New-York, Sydney & Shanghai.</p>
                    <br />
                </div>
                <div>

                <img src={Lab} className="lab_img" alt="Labelium Img" />
                    <div className="foot_foot">
                        <div className="foot_info">
                            <b>PARIS</b>
                            <br /><br />
                            3 rue d’Uzès, Paris, 75002, France.
                        </div>
                        <div className="foot_info">
                            <b>NEW YORK</b>
                            <br /><br />
                            55 Broadway, 24th Floor, New York, NY 10006 USA.
                        </div>
                        <div className="foot_info">
                            <b>MONTREAL</b>
                            <br /><br />
                            Suite 510, 460 rue Sainte Catherine Ouest, Montréal (QC), H3B 1A6, Canada.
                        </div>
                        <div className="foot_info">
                            <b>SYDNEY</b>
                            <br /><br />
                            Suite 204, 806/816 Anzac Parade, Maroubra, NSW, 2035, Australia.
                        </div>
                    </div>
                </div>
                <br/>
            </div>
            <br/>
            <div className="legal_info"><a href="https://www.footsprint.co/privacy-policy/">Legal notice</a> © All right reserved</div>
            <br/>
        </div>
    );
}



export default Footer;