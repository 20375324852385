import './Error.css';
import { useNavigate } from "react-router-dom";

const Error = () => {


    let nav = useNavigate();

    return (
        <div className="err_back">

            <div className="error_box">
                <div className="error_cross">
                    <a href="https://calculator.footsprint.co/" className="a_error">
                    x
                    </a>
                </div>

                <div className="error_box_inner">
                    <br /> <br />
                    <div className="oopsMsg">
                        <p className="oops">OOPS!</p>
                        WE'LL NEED TO GIVE IT ANOTHER SHOT
                        <a href="https://calculator.footsprint.co/" className="a_error"><button value="Please try again" className="sub">TRY AGAIN</button></a>
                    </div>
                    <br /> <br />
                    <div className="errorMsg">
                        <p>We couldn't perform the calculation due to one of the following reasons:</p>
                        <ul>
                            <li>The url might be incorrect, or the page does not exist.</li>
                            <li>The website traffic might be too low at the moment.</li>
                            <li>One of the APIs we're using might be experiencing an issue.</li>
                        </ul>
                        {/* <p>- The url might be incorrect, or the page does not exist.</p>
                    <br />
                    <p>- The website traffic might be too low at the moment.</p>
                    <br />
                    <p>- One of the APIs we're using might be experiencing an issue.</p> */}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Error;