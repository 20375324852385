import React from "react";
import { useState } from 'react';
import './Header.css';
import FootsprintHeader from '../Images/footsprint_header.png';

function Header() {

    const [burgerClass, setBurgerClass] = useState("hamburger");
    const [navMenu, setNavMenu] = useState("nav-menu");
    const [isMenuClicked, setIsMenuClicked] = useState(true);

    async function updateMenu(e) {
        e.preventDefault();
        if(isMenuClicked)
        {
            setBurgerClass("hamburger active");
            setNavMenu("nav-menu active")
        }
        else{
            setBurgerClass("hamburger");
            setNavMenu("nav-menu");
        }
        setIsMenuClicked(!isMenuClicked);
    }

    return (
        <div className="header">
                <a href="https://www.footsprint.co/" className="headImglink"><img src={FootsprintHeader} className="head_img" alt="Footsprint Header" height={75} width={295} /></a>
                <ul className={navMenu}>
                    <li className="nav-item"><a href="https://www.footsprint.co/" className="nav-link">Home</a></li>
                    <li className="nav-item"><a href="https://calculator.footsprint.co/" className="nav-link">Calculator</a></li>
                    <li className="nav-item"><a href="https://www.footsprint.co/blog/" className="nav-link">Blog</a></li>
                    {/* <li className="nav-item"><a href="https://www.footsprint.co/contact-us/" className="nav-link">Team</a></li> */}
                    <li className="nav-item"><a href="https://www.footsprint.co/contact-us/" className="nav-link">Contact</a></li>
                </ul>
                <div className={burgerClass} onClick={updateMenu}>
                    <span className="bar" ></span>
                    <span className="bar" ></span>
                    <span className="bar" ></span>
                </div>
            </div>
    );
}



export default Header;