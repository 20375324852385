
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Calculator.css';
import WebCalc from '../Images/Website_Calculator.svg';

import Loading from '../Loader/Loading';
import Error from '../Error/Error';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';



const Calculator = () => {

    const [loading, setLoading] = useState(false);

    let nav = useNavigate();

    const [uname, setUname] = useState('');
    const [userLoc, setUserLoc] = useState("US");
    const [err, setErr] = useState(false);

    const axios = require('axios');

    async function handleSubmit(e) {

        if (e)
            e.preventDefault();

        setLoading(true);

        let mainPage = uname;
        let mPage = '';

        if (mainPage.includes("https://www.") || mainPage.includes("https://") || mainPage.includes("http://")) {
            mPage = mainPage;
        }
        else if (mainPage.startsWith("www.")) {
            mPage = "https://" + mainPage;
        }
        else {
            mPage = "https://www." + mainPage;
        }

        let domain = (new URL(mPage));
        domain = domain.hostname.replace('www.', '');

        const body = {
            url: mPage,
            user_loc: userLoc
        }

        axios.post('https://footsprint.uc.r.appspot.com/getCalcValue', body)
            .then(response => {
                setLoading(false);
                if (!response.data.emission_perPageview) {
                    setErr(true);
                }
                else {
                    nav("/result", {
                        state: {
                            dm: mPage,
                            api_val: response.data
                        }
                    })
                }

            })
            .catch(error => {
                setLoading(false);
                setErr(true);
            });
    }

    async function handleDrop(e) {
        e.preventDefault();
        setUserLoc(e.target.value);
    }

    async function handlePress(e) {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    }


    return (
        <div className="main">
            <div className="page_container">
                {loading && <Loading />}
                <Header />

                <div className="headin">Website Page Carbon Calculator</div>

                <div className="basic">
                    <div>
                        <img src={WebCalc} className="calc_img" alt="Web Calc" height={500} width={440} />
                    </div>


                    <div className="textButton">

                        <form onSubmit={handleSubmit}>
                            <input className="enterURL" type="text" align="center" placeholder="enter URL" value={uname} onChange={(e) => setUname(e.target.value)} />
                            <br />
                            <br />
                            <label className="userLoc">

                                <b>Select end user location</b> &nbsp;

                                <select value={userLoc} onChange={handleDrop} onKeyPress={handlePress}>
                                    <option value="US">US</option>
                                    <option value="CA">CA</option>
                                    <option value="FR">FR</option>
                                    {/* <option value="Other">Other</option> */}
                                </select>

                            </label>

                            <br /> <br />
                            <div className="buttons">

                                <button type="submit" value="Calculate Emission" className="submit" onClick={handleSubmit}>Calculate My Page's Emissions</button>
                                <a href="https://www.footsprint.co/integrating-sustainability-into-your-website-development-lifecycle-chapter1/" className="i_link"><button type="button" className="iImage" >i</button></a>
                            </div>

                        </form>


                    </div>
                    {err && <Error />}


                </div>
            </div>
            <br />
            <div className="footer_div">
                <Footer />
            </div>


        </div>

    );
}

export default Calculator;