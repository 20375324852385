import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Calculator from './Calculator/Calculator';
import Error from './Error/Error';

const Result = React.lazy(() => import('./Result/Result'))

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Calculator />}></Route>
        {/* <Route path='/result' element={<Result />}></Route> */}
        <Route path='/result' element={<React.Suspense fallback={<p>Loading...</p>}> <Result /></React.Suspense>}></Route>
        <Route path='/error' element={<Error />}></Route>
      </Routes>
    </Router>
  );
}

export default App;